import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux' 
import {getUserAction} from '../actions/auth/authAction'

import queryString from 'query-string';


class HeaderSearchForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue:'',
    
    }

    this.changeInput= this.changeInput.bind(this);
    this.submitForm= this.submitForm.bind(this);
  
  }

  changeInput =(e)=>{

    this.setState({
     [e.target.name]:e.target.value
    })
  }
  submitForm =(e)=>{

    e.preventDefault();
    const {searchValue} = this.state

if(this.props.SendSearchText){
  this.props.SendSearchText(searchValue)
  
}
if(searchValue && !this.props.SendSearchText){
  this.props.history.push(`/search?deal=${searchValue}`)
}
  

  }


  componentDidMount(){
    const {deal} = queryString.parse(this.props.location.search)

    if(deal){

      this.setState({

        searchValue:deal
      })
    }


  }



  render() {

    const {searchValue} = this.state
    return (
     
            <div className="col-md-4 col-sm-10"> 
              <div className="searchbar mtb-5">
                <form className="mb-0">
                  <input type="text" className="form-control" name="searchValue" value={searchValue} placeholder="Search here" onChange={this.changeInput} />
                  <span className="icon-search pointer" onClick={this.submitForm} >
                          <input type="submit" value="" className="submit-icon"/>
                        </span>
                </form>
              </div>
            </div>
          

    );
  }
}

const mapStateToProps = state => ({
 userInfo:state.profileReducer.result

})
const mapDispatchToProps = dispatch => ({
  getUser: () => dispatch(getUserAction()),
  

})
export default connect(mapStateToProps, mapDispatchToProps)(HeaderSearchForm);