import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {getPopularDealsAction} from '../actions/dealsAction'
import GoogleAd from './googleAd';
import GoogleAd_b from './googleAd_bottom';

class SideBar extends Component {
  constructor(props) {
    super(props)
  



  }

  componentDidMount() {

    this.props.getPopular()


  }

 



  componentWillUnmount() {

  
  }






  render() {

    const { pathname } = this.props.history.location

  
    return (   
              <div className="col-lg-3 col-md-4">
                <div className="ad_banner_wrap">
                  {/* <a href="/"><img alt="Ads-01.png" src={require('./../images/Ads-01.png')} /></a> */}
     {/* comment for dev            */}
<GoogleAd key={`${pathname}key`} />


                </div>
                <div className="popular_deals">
                  <div className="deals_title">
                    <span>Popular Deals</span>
                    {/* <span><i className="fa fa-rss" aria-hidden="true" /></span> */}
                  </div>
                  <div className="deals_items">
                    <ul>
                      {this.renderDeals()}
                    </ul>
                  </div>
                </div>
                <div className="ad_banner_wrap">
                  {/* <a href="/"><img alt="Ads-02.png" src={require('./../images/Ads-02.png')} /></a> */}
     {/* comment for dev            */}
                  <GoogleAd_b key={`${pathname}key`} />



                </div>
              </div>
          
    )
  }

  renderDeals(){

    const {popularLoader,popularDeals} = this.props

return popularDeals&& popularDeals.map((deal, idx)=>{

return (  <li>
  <div className="deals_item_title">
   <a  href={`/deal/${deal.id}`}>{deal.del_title}</a> 
  </div>
  <div className="voting_icons">
    <span className="voting_label">
      <span className="likes_icon"><i className="fa fa-thumbs-up" aria-hidden="true" /></span>
      <span className="likes_count">{(deal.del_count_like - deal.del_count_dislike)}</span>
     
    </span>
    <span className="comment_label">
      <span className="comment_icon"><i className="fa fa-comment" aria-hidden="true" /></span>
      <span className="comment_count">{deal.del_count_comments}</span>
    </span>
    <div className="clearfix"></div>
  </div>
</li>)
})

  }

 

}

const mapStateToProps = state => ({
popularDeals: state.popularDealsReducer.result,
popularLoader: state.popularDealsReducer.loader

})
const mapDispatchToProps = dispatch => ({

  getPopular: ()=> dispatch(getPopularDealsAction())
 
})
export default connect(mapStateToProps, mapDispatchToProps)(SideBar);