//production server easyrun.com.au
export const imagePath = 'https://easyrun.com.au/easyrun/public/upload/dealimages' ;
export const avatarPath = 'https://easyrun.com.au/easyrun/public/upload/avatars' ;
export const url =  'https://easyrun.com.au/easyrun/';


//dev server easyrundev.vtrio.com
// export const imagePath = 'https://easyrundev.vtrio.com/easyrun/public/upload/dealimages' ;
// export const avatarPath = 'https://easyrundev.vtrio.com/easyrun/public/upload/avatars' ;
// export const url =  'https://easyrundev.vtrio.com/easyrun/';

//UAT server easyrun.vtrio.com
// export const imagePath = 'https://easyrun.vtrio.com/easyrun/public/upload/dealimages' ;
// export const avatarPath = 'https://easyrun.vtrio.com/easyrun/public/upload/avatars' ;
// export const url =  'https://easyrun.vtrio.com/easyrun/';

export const ACCESS_TOKEN ='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJjbGllbnRpZCI6Ijc1QTI3QjZFLTkwOUUtMkNFRC1BRUJGLTFFRDBBN0M4RUExMiJ9.zR9nVlXk-6XjOn_y3NyNBnrQ676207cWAClnI6TfNxw0UaIDUp3ZN_ud117ZPLSQMC0ry-tGUfOmLvgsLqRQIA';

//beta       
// export const url =  'https://easyrun.vtrio.com/easyrunbeta/';
// export const imagePath = 'https://easyrun.vtrio.com/easyrunbeta/public/upload/
// export const avatarPath = 'https://easyrun.vtrio.com/easyrunbeta/public/upload/avatars' ;dealimages';


// Recaptcha site key
// export const reCaptchaSiteKey= "6Lf9Z9MUAAAAAE5fK8hWNLCR2kuo1LmGielRCOC7"//localhost
// export const reCaptchaSiteKey= "6LcIMtUUAAAAAENpI5VGokgi4WgyReBkIPOn97NP"//easyrun.vtrio.com
export const reCaptchaSiteKey= "6LcZYOAUAAAAAMcbD3_3ldBmkpmpulmBDzn2XlkV"//easyrun.com.au

//like or dislike button
export const likeStyle= {    'color': '#BBDA90','cursor':'pointer'}
export const dislikeStyle= {    'color': '#F1ACAD','cursor':'pointer'}

export const dealEditablePeriod= 7  //days
export const perPage= 20   //nos

