import {perPage} from '../helpers/constants';

export default (state = { loader: false, error: false,result: [], skip: 0, limit: perPage, hasMore: true}, action) => {
  switch (action.type) {
    case 'DEAL_FETCH_BEGINS':
      return {
        ...state,
        loader: true,
        error: false,
        skip: action.skip,
        limit: action.limit,
        hasMore: true
      }

    case 'DEAL_FETCH_SUCCESS':
      return {
        skip: state.skip,
        limit: state.limit,
        loader: false,
        error: false,
        result: state.result.concat(action.payload.deals), 
        hasMore: action.hasMore
      }

    case 'DEAL_FETCH_FAILURE':
      return {
        skip: state.skip,
        limit: state.limit,
        loader: false,
        error: true,
        // result: [],
        hasMore: false
      }

      case 'DEAL_FETCH_SKIP':
        return {
          skip: state.skip,
          limit: state.limit,
          loader: false,
          error: false,
          // result: [],
          hasMore: false
        }

    case 'DEAL_FETCH_CLEAR':
      return {
        loader: false, 
        error: false,
        result: [], 
        skip: 0, 
        limit: perPage, 
        hasMore: true
      }
    default:
      return state
  }
}